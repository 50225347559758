.resumeContainer {
  padding-top: 180px;
}

.years {
  text-align: center;
  background-color: rgba(173, 178, 187, 0.6);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  width: 72px;
  height: 34px;
  border-radius: 20px;
  display: block;
  font-size: 22px;
  margin-bottom: 8px;
}

.lines {
  border-bottom: 1px solid white;
  width: 100%;
  display: block;
  margin-bottom: 10px;
}

@media screen and (max-width: 992px) {
  .awardsHeader {
    height: 78px !important;
    width: 38% !important;
    padding-top: 4px;
  }
}

@media screen and (min-width: 768px) {
  .bioHeader {
    padding-bottom: 40px !important;
  }
}

@media screen and (max-width: 768px) {
  .bioHeader {
    padding-top: 4px;
  }
}

@media screen and (max-width: 500px) {
  .awardsHeader {
    padding-top: 8px;
  }
}


@media screen and (max-width: 769px) {
  .resumeContainer {
    padding-top: 210px;
  }
}

@media screen and (max-width: 548px) {
  .bio {
    margin-top: 160px !important;
  }
}
