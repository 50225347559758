html, body {
    width: auto;
}

@media screen and (max-height: 800px) {
    html {
    min-height: auto;
    }
}

body, #app, app>div, #root {
    background-color: transparent !important;
}

html {
    background-color: rgb(178, 178, 178);
    color: rgba(41, 44, 49, 0.75);
}