

@media screen and (max-width: 548px){
    .contact {
        margin-top: 160px !important;
    }
    }

    @media screen and (max-width: 710px){
        .contactHeader {
            padding-top: 3px;
            width: 40% !important;
        }
        }