.portfolio {
    margin-top: 160px;
}

.MuiPopover-paper {
    border-radius: 20px !important;
    background-color: rgb(27, 27, 27) !important;
    -webkit-backdrop-filter: blur(20px) !important;
    backdrop-filter: blur(20px) !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 100%;
  }

  /* .card {
    bs-bg-opacity: ;
  } */

@media screen and (max-width: 1200px) {
    .recent {
        width: 28% !important;
    }
}

@media screen and (max-width: 1000px) {
    .recent {
        width: 32% !important;
    }
}

@media screen and (max-width: 768px) {
    .recent {
        width: 38% !important;
        margin-top: 25px;
        padding-top: 4px;
    }
}

.css-8je8zh-MuiTouchRipple-root {
    cursor: pointer;
}

@media screen and (max-width: 658px) {
    .nextBtn {
        top: 580px !important;
    }
    .prevBtn {
        top: 580px !important;

    }
    .popCard {
        margin-top: -50px;
    }
}

/* @media screen and (min-width: 680px) {
    .popCard {
        max-height: 100% !important;
    }
} */

@media screen and (max-width: 598px) {
    .popCard {
        margin-top: -100px;
    }
    .popInfo {
        margin-top: -110px;
    }
}

@media screen and (max-width: 548px){
    .gallery {
        margin-top: 50px !important;
    }
    }

@media screen and (max-width: 482px) {
    .portfolio {
        margin-top: 140px;
    }
}

@media screen and (max-width: 468px) {
    .recent {
        width: 48% !important;
    }
}